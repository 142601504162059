import React, { useMemo } from "react"
import { Layout } from "@components/layout"
import { Link, useStaticQuery, graphql } from "gatsby"
import { AnimatedSection } from "@components/shared"
import BG from "../../components.refactored/standard/Home/icons/thumbnail.jpg"
import Play from "../../components.refactored/standard/Home/icons/play.svg"

import {
  wrapper,
  wrapper__videos,
  wrapper__videos__video,
  wrapper__videos__video__content,
  wrapper__videos__video__image,
  wrapper__videos__video__image__overlay,
} from "./styles/records.module.scss"

function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + "..."
}
const Videos = (props) => {
  const { videos } = props
  const revVideos = useMemo(() => [].concat(videos).reverse(), [])
  return (
    <AnimatedSection className={wrapper}>
      <h1>Nagrania z live Gladiatorów</h1>
      <p>
        Coś Cię ominęło? Nie szkodzi. Oto wszystkie nagrania w jednym miejscu!
      </p>
      <div className={wrapper__videos}>
        {revVideos.map((el, i) => (
          <div className={wrapper__videos__video}>
            <div className={wrapper__videos__video__image}>
              <img src={BG}></img>
              <div className={wrapper__videos__video__image__overlay}>
                <a href={el.url} target="_blank">
                  <img alt="play" src={Play}></img>
                  <small>Kliknij, aby odtworzyć nagranie</small>
                </a>
              </div>
            </div>
            <div className={wrapper__videos__video__content}>
              <time>{el.date}</time>
              <p>{truncateString(el.description, 170)}</p>
            </div>
          </div>
        ))}
      </div>
    </AnimatedSection>
  )
}

const Records = ({ pageContext }) => {
  const { page } = pageContext
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        pageBy(id: "cGFnZToyOQ==") {
          ACF_Videos {
            videos {
              date
              url
              description
            }
          }
        }
      }
    }
  `)
  const { videos } = WP.pageBy.ACF_Videos
  return (
    <Layout {...page}>
      <Videos videos={videos} />
    </Layout>
  )
}

export default Records
